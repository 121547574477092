import React from "react"
import { Helmet } from "react-helmet"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import MainImage from "../images/marketing/Real-Estate-Business-Plan-Main-Image-LaunchPlan-Software.jpg"
import DescriptionImage from "../images/marketing/Real-Estate-Company-Description-LaunchPlan-Software.jpg"
import ExecutiveSummaryImage from "../images/marketing/Real-Estate-Executive-Summary-LaunchPlan-Software.jpg"
import FinanacialPlanImage from "../images/marketing/Real-Estate-Financial-Plan-LaunchPlan-Software.jpg"
import OperationsImage from "../images/marketing/Real-Estate-Investment-Operations-Plan-LaunchPlan-Software.jpg"
import MarketingImage from "../images/marketing/Real-Estate-Marketing-and-Sales-Plan-LaunchPlan-Software.jpg"
import PublicLayout from "../components/layouts/PublicLayout"
import Button from "../components/button"

const styles = theme => ({
  root: {
    backgroundColor: "white",

    '& img': {
      marginBottom: '40px',
      maxWidth: '100%'
    }
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.primary.borderColor}`,
  },
  text: {
    margin: '0 auto',
    maxWidth: 600,

    '& p': {
      marginBottom: '20px'
    },

    '& h2, h3': {
      margin: '100px 0 30px'
    }
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  headline: {
    margin: "1rem 0",
  },
  separator: {
    width: 60,
    height: 4,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 4,
    margin: "1.5rem 0",
  },
  footer: {
    marginTop: '120px'
  }
})

const RealEstatePage = props => {
  const { classes } = props

  return (
    <PublicLayout
      headline={"Write a Real Estate Business Plan with LaunchPlan Software"}
      canonicalUrl="https://launchplan.com/real-estate-business-plan-software"
    >
      <Helmet>
        <title>Real Estate Business Plan Software | LaunchPlan</title>
        <meta
          name="description"
          content="Write business plans for real estate agents, real estate investing and development companies, and rental property firms using LaunchPlan  software. "
        />
        <meta
          property="og:title"
          content="Real Estate Business Plan Software | LaunchPlan"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://launchplan.com/real-estate-business-plan-software" />
        <meta
          property="og:image"
          content="https://launchplan.com/launchplan-logo-vertical.png"
        />

        {/* <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content="206" />
          <meta property="og:image:height" content="112" /> */}
        <meta
          property="og:image:alt"
          content="The LaunchPlan logo, a rocket launching"
        />
      </Helmet>
      <section className={classes.root}>
        <img src={MainImage} alt='' />
        <div className={classes.text}>
          <Typography>
            If you’re thinking about starting a real estate business, it’s important to outline a clear path to profitability for loan providers, potential investors and — most importantly — your own benefit.
          </Typography>
          <Typography>
            Simply putting more time into a new business — whether it’s investing in rental properties or becoming a real estate agent — does not necessarily translate into making more money. A real estate business plan can ensure your time investment is designed to make your company viable.
          </Typography>
          <Typography>
            But don’t just take our word for it. A recent Harvard Business Review study found that entrepreneurs who write business plans are <a target="_blank" href="https://hbr.org/2017/07/research-writing-a-business-plan-makes-your-startup-more-likely-to-succeed">16% more likely to achieve viability</a> than those who do not.
          </Typography>
          <Typography>
            The outline of a business plan features five main areas, including an executive summary, a company description, an operations plan, marketing & sales plans, and a financial plan.
          </Typography>
          <Typography>
            Read on for an overview, or let our real estate <a target="_blank" href="https://launchplan.com/">business plan software</a> walk you through each step and automate your financial forecasts with a 60 day risk-free guarantee. You'll get a 100% refund if you're not 100% satisfied..
          </Typography>
          <Button
            className={classes.freeTrailButton}
            href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}
            onClick={() =>
              trackCustomEvent({
                category: "Restaurant Plan Help",
                action: "Click",
                label: "Free Trial In Overview",
              })
            }
          >
            {"Create Your Plan with LaunchPlan"}
          </Button>
          <Typography component="h2" variant="h4">
            Executive Summary
          </Typography>
          <div className={classes.separator}></div>
          <img src={ExecutiveSummaryImage} alt='' />
          <Typography>
            The executive summary addresses the key parts of your plan from a high level and is an expanded elevator pitch to potential investors and banks. It should answer common questions about your real estate business, like the specific services it will offer, what makes it unique, its target neighborhoods and demographics, and how it will make money.
          </Typography>
          <Typography>
            The executive summary should be written as if it’s the only thing an investor will read — because they may not have time for anything else.
          </Typography>
          <Typography>
            As a real estate business, some tangible details to feature in the executive summary include:
          </Typography>
          <ul>
            <li>
              <Typography>
                A few illuminating statistics about the real estate market you’re entering

                (Ex. “Highlighting increased demand, average home prices rose by 16%, or by $44,000, in our Boise, Idaho market in 2019.”)
              </Typography>
            </li>
            <li>
              <Typography>
                Four to six SMART (specific, measurable, assignable, realistic and time-based) goals for the first year of operations

                (Ex. “Close 10 property sales of $200,000 or greater with first-time homebuyers” if you’re a real estate agent, or “Purchase three rental properties in the $300,000 to $450,000 price range in up-and-coming Chicago neighborhoods” if you’re a real estate investment company)
              </Typography>
            </li>
            <li>
              <Typography>
                A high-level overview of upfront expenses, the amount of funding needed and revenue projections over the first three years of the business
              </Typography>
            </li>
          </ul>
          <Typography component="h2" variant="h4">
            Company Description
          </Typography>
          <div className={classes.separator}></div>
          <img src={DescriptionImage} alt='' />
          <Typography>
            The company description goes into more detail about your business’ background and vision, including its history and team, as well as its industry and competitive landscape. This section addresses the company’s long-term goals and how it plans to achieve them. It highlights how your company fits in with your real estate market’s trends, and how it can stand apart from or coexist with competitors.
          </Typography>
          <Typography>
            The anchors of the company description are the mission and vision statements. While the mission statement articulates the day-to-day benefits your company will provide to its customers, employees (including yourself) and shareholders; the vision statement takes a broader view of how you will move the market forward and benefit the community at large.
          </Typography>
          <Typography>
            As a real estate business, consider exploring the following aspects of your market and how your company can thrive amongst them:
          </Typography>
          <ul>
            <li>
              <Typography>
                The average commission rates in your target market if you’re a real estate agent
              </Typography>
            </li>
            <li>
              <Typography>
                If you’re a rental property company, the local laws surrounding long-term vs. short-term (i.e. Airbnb and Vrbo) rentals in your target market
              </Typography>
            </li>
            <li>
              <Typography>
                Trend of average days on market for real estate in recent years
              </Typography>
            </li>
            <li>
              <Typography>
                Trend of <a target="_blank" href="https://www.zillow.com/home-values/">average home sale prices</a> in recent years
              </Typography>
            </li>
            <li>
              <Typography>
                Trend of active listing inventory levels in recent years
              </Typography>
            </li>
            <li>
              <Typography>
                Any aspects of the market that make it resistant to a recession
              </Typography>
            </li>
          </ul>
          <Typography component="h2" variant="h4">
            Operations Plan
          </Typography>
          <div className={classes.separator}></div>
          <img src={OperationsImage} alt='' />
          <Typography>
            If an investor makes it to your operations plan, then they’re likely intrigued and want the nitty-gritty on how you’ll turn your ideas into action. The operations plan details the physical assets, personnel and technology you will need to become a viable real estate business.
          </Typography>
          <Typography>
            This section of a business plan details capital and expense requirements like your office space, computer workstations, realtor training and certification, website, digital advertising, and printed signs and brochures. In addition, a real estate investment firm should detail their investments into the properties that will be rented out.
          </Typography>
          <Typography>
            If you will have other personnel such as a partner, sales person or assistant, their roles in day-to-day operations should also be clearly described here, in addition to your own role.
          </Typography>
          <Typography component="h2" variant="h4">
            Marketing & Sales Plans
          </Typography>
          <div className={classes.separator}></div>
          <img src={MarketingImage} alt='' />
          <Typography>
            The marketing & sales plans detail who your target prospects will be, how you will reach them, and how you will convert them into paying customers.
        </Typography>
          <Typography>
            First and foremost, your real estate business should define the niche — or various niches — that you’ll target in the market. Will it be first-time home buyers or luxury buyers? Will you be renting to low-income families and college students, or targeting white-collar professionals with more disposable income?
        </Typography>
          <Typography>
            Once you’ve defined your niches, you can perform market research and develop a plan to reach them. For digital marketing, SEO tools like <a target="_blank" href="https://www.semrush.com/">SEMrush</a> and <a target="_blank" href="https://moz.com/">Moz</a> can reveal the volume of different real estate Google searches (ex. “luxury homes in Raleigh NC”) so that you can gauge market demand. You then can check out the websites and social media presence for competitors who are appearing for these searches to get ideas or determine what you could do differently.
        </Typography>
          <Typography>
            On social media, it’s important to know your audience. Just <a target="_blank" href="https://www.pewresearch.org/internet/fact-sheet/social-media/">8% of Americans ages 65+ say they use Instagram</a>, for example, so if you’re selling luxury homes in retirement communities, you’d probably be better off using word-of-mouth campaigns and more traditional channels. With the <a target="_blank" href="https://www.bloomberg.com/news/articles/2019-11-08/young-homebuyers-vanish-from-u-s-as-median-purchasing-age-jumps">median age of first-time homebuyers being 33 years old</a>, Facebook could more effectively reach that niche (79% of Americans 30 to 45 years old use Facebook).
          </Typography>
          <Typography component="h2" variant="h4">
            Financial Plan
          </Typography>
          <div className={classes.separator}></div>
          <img src={FinanacialPlanImage} alt='' />
          <Typography>
            Often the most intimidating part of a business plan, the financial plan, includes your profit & loss statement, your balance sheet and your cash flow. Our business plan software for real estate simplifies this process by asking for your financial numbers like TurboTax does for taxes, and then automating them into clean charts and graphs.
        </Typography>
          <Typography component="h3" variant="h5">
            Profit & Loss Statement
        </Typography>
          <Typography>
            The profit & loss statement breaks down your estimated profit and loss over the first few years of business, taking into account revenues from sales commissions or rent, your expenses, employee payroll and corporate taxes.
        </Typography>
          <Typography>
            Given that real estate development and rental property companies often require a larger upfront investment to secure their properties, they will typically take longer to turn a profit than a real estate agency or brokerage.
        </Typography>
          <Typography>
            In either case, you’ll want your profit & loss to show an upward trajectory over the first few years.
        </Typography>
          <Typography component="h3" variant="h5">
            Balance Sheet
          </Typography>
          <Typography>
            While your profit & loss statement focuses on trends, your balance sheet details your financial status at a set point in time, laying out your assets, liabilities and equity.
          </Typography>
          <Typography component="h3" variant="h5">
            Cash Flow
          </Typography>
          <Typography>
            Lastly, your cash flow shows the net amount of cash or cash-equivalents flowing in and out of your real estate business, broken down into operations, investing and financing.
          </Typography>
          <div className={classes.footer}>
            <div className={classes.separator}></div>
            <Typography>
              Need more help writing your real estate business plan? Let LaunchPlan walk you through each step.
            </Typography>
            <Typography>
              LaunchPlan lets you collaborate with unlimited team members and then easily download your professional business plan. Impress potential investors and put your company on track for success.
            </Typography>
            <Button
              className={classes.freeTrailButton}
              href={`${process.env.GATSBY_APP_HOST}/signup?selection=FREE_ACCOUNT`}
              onClick={() =>
                trackCustomEvent({
                  category: "Restaurant Plan Help",
                  action: "Click",
                  label: "Free Trial In Overview",
                })
              }
            >
              {"Start Your LaunchPlan Free Trial"}
            </Button>
          </div>
        </div>
      </section>
    </PublicLayout>
  )
}

export default withStyles(styles)(RealEstatePage)
